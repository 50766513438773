import React, { Component } from "react";
import Quate from "./components/Quate";

class App extends Component {
  render() {
    return <Quate />;
  }
}

export default App;
